<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <Date-picker format="yyyy-MM-dd" v-model="time" type="daterange" placeholder="选择日期" @on-change="handleChangeTime"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'Blood'}" >统计显示</router-link>
          <router-link :to="{name: 'BloodTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'BloodChart'}">图表显示</router-link>
        </div>
      </div>
      <Row :gutter="40">
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="card-module bg1">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-1.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="'舒张压最低：' + min_diastolic + 'mmHg'"><em class="font_30">{{min_diastolic}}</em>mmHg</p>
                    <h2 :title="'舒张压最低：' + min_diastolic + 'mmHg'">舒张压最低</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-2.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="'舒张压最高：' + max_diastolic + 'mmHg'"><em class="font_30">{{max_diastolic}}</em>mmHg</p>
                    <h2 :title="'舒张压最高：' + max_diastolic + 'mmHg'">舒张压最高</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div class="card-footer bg1">
            <Row>
              <Col :lg="{span: 8, offset: 1}" :md="{span: 11, offset: 1}">低于标准：{{low_d}}次</Col>
              <Col :lg="{span: 8}" :md="{span: 11}">超出标准：{{high_d}}次</Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="card-module bg2">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-3.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="'收缩压最低：' + min_systolic + 'mmHg'"><em class="font_30">{{min_systolic}}</em>mmHg</p>
                    <h2 :title="'收缩压最低：' + min_systolic + 'mmHg'">收缩压最低</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-4.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="'收缩压最高：' + max_systolic + 'mmHg'"><em class="font_30">{{max_systolic}}</em>mmHg</p>
                    <h2 :title="'收缩压最高：' + max_systolic + 'mmHg'">收缩压最高</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div class="card-footer bg2">
            <Row>
              <Col :lg="{span: 8, offset: 1}" :md="{span: 11, offset: 1}">低于标准：{{low_s}}次</Col>
              <Col :lg="{span: 8}" :md="{span: 11}">超出标准：{{high_s}}次</Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="card-module bg3">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-5.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="'心率最低：' + min_heart + '次/分'"><em class="font_30">{{min_heart}}</em>次/分</p>
                    <h2 :title="'心率最低：' + min_heart + '次/分'">心率最低</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-6.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="'心率最高：' + max_heart + '次/分'"><em class="font_30">{{max_heart}}</em>次/分</p>
                    <h2 :title="'心率最高：' + max_heart + '次/分'">心率最高</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div class="card-footer bg3">
            <Row>
              <Col :lg="{span: 8, offset: 1}" :md="{span: 11, offset: 1}">低于标准：{{low_h}}次</Col>
              <Col :lg="{span: 8}" :md="{span: 11}">超出标准：{{high_h}}次</Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			Blood: {
				start: '',
				end: '',
				index: 0,
				page: 1,
				size: 1,
			},
			time: [],
			max_diastolic: '',
			low_s: '',
			max_heart: '',
			max_systolic: '',
			min_systolic: '',
			high_d: '',
			min_heart: '',
			low_h: '',
			low_d: '',
			high_h: '',
			min_diastolic: '',
			high_s: '',
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		firstDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-01`;
		},
		nowDate() {
			let d = new Date();
			let month = d.getMonth() + 1;
			month = month > 9 ? month : '0' + month;
			let day = d.getDate();
			day = day > 9 ? day : '0' + day;
			return `${d.getFullYear()}-${month}-${day}`;
		},
		last30Date() {
			// 获取当前日期
			var myDate = new Date();

			// 获取三十天前日期
			var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);
			var lastY = lw.getFullYear();
			var lastM = lw.getMonth() + 1;
			var lastD = lw.getDate();
			var startdate =
				lastY + '-' + (lastM <= 9 ? '0' + lastM : lastM) + '-' + (lastD <= 9 ? '0' + lastD : lastD);

			return startdate;
		},
	},
	methods: {
		handleChangeTime(value) {
			this.Blood.start = value[0];
			this.Blood.end = value[1];
			this.time = value;
			this.handleQuery();
		},
		handleQuery() {
			this.Blood.member_id = this.memberId;
			MemberService.bloodQuery(this.Blood).then((data) => {
				if (
					data.max_diastolic === null ||
					data.min_diastolic === null ||
					data.max_systolic === null ||
					data.min_systolic === null ||
					data.max_heart === null ||
					data.min_heart === null
				) {
					this.max_diastolic = 0;
					this.min_diastolic = 0;
					this.max_systolic = 0;
					this.min_systolic = 0;
					this.max_heart = 0;
					this.min_heart = 0;
				} else {
					this.max_diastolic = data.max_diastolic.toFixed(1);
					this.min_diastolic = data.min_diastolic.toFixed(1);
					this.max_systolic = data.max_systolic.toFixed(1);
					this.min_systolic = data.min_systolic.toFixed(1);
					this.max_heart = data.max_heart.toFixed(1);
					this.min_heart = data.min_heart.toFixed(1);
				}
				this.low_s = data.low_s;
				this.high_s = data.high_s;
				this.high_d = data.high_d;
				this.low_d = data.low_d;
				this.high_h = data.high_h;
				this.low_h = data.low_h;
			});
		},
	},
	created() {
		// this.time = [this.firstDate, this.nowDate]
		this.time = [this.last30Date, this.nowDate];
		this.Blood.start = this.last30Date;
		this.Blood.end = this.nowDate;
		this.handleQuery();
	},
};
</script>
<style lang="css" scoped>
.data-contain .personal-search {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
}
.data-contain .personal-date {
  width: 470px;
  text-align: center;
  display: inline-block;
}
.tab-contain {
  float: right;
}
.tab-contain a {
  padding: 6px;
  border: 1px solid #333543;
  color: #999;
  float: left;
  display: inline-block;
  font-size: 12px;
}
.tab-contain .static-button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
}
.tab-contain .chart-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
}
.tab-contain .active {
  background-color: #333543;
  color: #fff;
}
</style>
